












































































































import { api } from "@/api/api";
import { ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import MemberOrganizationUserModal from "@/components/administration/member-organization-users/MemberOrganizationUserModal.vue";
import MemberOrganizationModal from "@/components/administration/member-organizations/MemberOrganizationModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "MainOfficePage",
  components: {
    BaseSheet,
    BaseSheetHeader,
    BaseSheetField,
    BaseModal,
    BaseTooltipIconButton,
    BaseTableFiltered,
    MemberOrganizationModal,
    MemberOrganizationUserModal,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const modalData = ref(getInitialModalData());
    const mainOffice = ref();
    const offices = ref<ApiGetMemberOrganizationDto[]>([]);
    const regionOffices = ref<ApiGetMemberOrganizationDto[]>([]);
    const contentReady = ref(false);

    const headers = [
      { text: "Navn", value: "name" },
      { text: "E-post", value: "email" },
      {
        text: "Aktiv",
        value: "isActive",
      },
      { text: "Handlinger", value: "actions" },
    ];

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        mainOffice.value = (await api.organization.getMemberOrganizationAsync(+route.params.mainOfficeId)).data;
        await getAllRegionOffices();
        contentReady.value = true;
      });
    });

    const openNewOrg = useOpenModal(ModalType.Add, "regionkontor", modalData);
    const openEditOrg = useOpenModal(ModalType.Edit, "regionkontor", modalData);
    const viewMemberOrgUsers = useOpenModal(ModalType.Display, "Legg til e-post - regionkonto", modalData);

    const getAllRegionOffices = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        offices.value = (await api.organization.getMemberOrganizationsAsync()).data;
        if (mainOffice.value) {
          regionOffices.value = offices.value.filter((item) => item.parentId == mainOffice.value.id);
        }
      });
    };
    const viewRegionOffice = async (item: ApiGetMemberOrganizationDto) => {
      router.push({
        name: AdminRouteNames.RegionOffice,
        params: { mainOfficeId: route.params.mainOfficeId, regionOfficeId: item.id.toString() },
      });
    };

    const navigateToMemberOrgs = () => {
      router.push({
        name: AdminRouteNames.MemberOrganizations,
      });
    };

    return {
      formatDate,
      getAllRegionOffices,
      viewRegionOffice,
      contentReady,
      ModalType,
      modalData,
      openNewOrg,
      openEditOrg,
      viewMemberOrgUsers,
      mainOffice,
      offices,
      regionOffices,
      headers,
      navigateToMemberOrgs,
    };
  },
});

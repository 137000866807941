








import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "BaseSheetField",
  props: {
    value: {
      type: [String, Number, Boolean, Object],
      required: false,
    },
    dataCy: { type: String, required: false },
    isXaxisMargin: {
      type: Boolean,
      default: true,
    },
    isYaxisMargin: {
      type: Boolean,
      default: false,
    },
    label: [String, Number],
  },
  setup(props) {
    const valueType = typeof props.value;
    const valueString = computed(() => {
      if (valueType === "string" || valueType === "number") {
        return props.value;
      }
      if (valueType === "boolean") {
        return props.value ? "Ja" : "Nei";
      }
      if (props.value instanceof Object) {
        const valueObject = props.value as { name: string; description: string };
        const name: string = valueObject.name;
        const description: string = valueObject.description;
        return name ?? description;
      }
      return "-";
    });

    return { valueString };
  },
});
